
.Manage_box {
    width: 100%;
    height: 100%;
    display: flex;

    .box_left {
        flex: 1;
        width: 0;
        height: 100%;

        .box_header {
            width: 100%;
            height: 112px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            display: flex;
            align-items: center;
            padding-left: 24px;
            box-sizing: border-box;
            padding: 24px;
            box-sizing: border-box;

            .header {

                width: 64px;
                height: 64px;
                border-radius: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }

            }

            .info {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .name {
                    width: 217px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }

                .number {
                    color: #7A7E88;

                }

            }

            .switch_account {
                width: 100px;
                height: 100%;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #888888;
                text-align: left;
                font-style: normal;
                text-transform: none;
                i{
                    margin-left: 5px;
                }

            }

            .account_info {
                display: flex;
                height: 100%;
              

                .item {
                    width: 100px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 16px;
                    color: #4D4F56;

                    span {
                        display: inline-block;
                    }

                    .number {
                        font-family: Douyin Sans, Douyin Sans;
                        font-weight: bold;
                        font-size: 22px;
                        color: #171A1E;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;

                    }
                }
            }

        }

    }
    .show_box{
        width: 100%;
        height: calc(100vh - 235px);
        margin-top: 20px;
        background-color: #fff;
        border-radius: 4px;
    }

    // .box_right {
    //     width: 200px;
    //     height: calc(100vh - 100px);
    //     background-color: pink;

    // }

}
